import { DatePicker, Select, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { IUnidadeCleanDTO } from '../../../../models/happy-code-api.model';
import { IOperacoesPagarMeV4DTO } from '../../../../models/relatorio.model';
import { usePagarMeV4Service } from '../../../../services/pagarmeV4.service';
import { useUnidadeService } from '../../../../services/unidade.service';
import { ExportToExcel } from '../../../../util/exportToExcel';
import { useDadosExportExcel } from './hooks/use-dados-export-excel.hook';
import TableColumns from './table-columns';
import TableColumnsOperacao from './table-columns-operacao';

const { RangePicker } = DatePicker;
const { Option } = Select;

function RelConciliacaoBancariaSedeNew() {
	const { setIsGlobalLoading } = useGlobalContext();
	const unidadeService = useUnidadeService();
	const servicePagarMeV4 = usePagarMeV4Service();
	const [dadosPagarMeV4, setDadosPagarMeV4] = useState<IOperacoesPagarMeV4DTO[]>();
	const [dataInicio, setDataInicio] = useState<moment.Moment>();
	const [dataFim, setDataFim] = useState<moment.Moment>();
	const [data, setData] = useState<IOperacoesPagarMeV4DTO[]>([]);
	const [unidade, setUnidade] = useState<IUnidadeCleanDTO[]>();
	const [unidadeRecipienteId, setUnidadeRecipienteId] = useState<string | null>();
	const filtroStyle = { minWidth: 200, border: '1px solid gainsboro' };

	const findUnidade = () => {
		return unidadeService
			.findCleanList()
			.then((response) => setUnidade(response.data));
	};

	const setaDataInicial = () => {
		const dataFimQuandoAbreATela = moment();
		setDataFim(dataFimQuandoAbreATela);

		const dataInicioQuandoAbreATela = moment().subtract(5, 'days');
		setDataInicio(dataInicioQuandoAbreATela);
	};

	useEffect(() => {
		findUnidade();
		setaDataInicial();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getPeriodo = (): any => {
		if (dataInicio && dataFim) {
			return [moment(dataInicio, 'YYYY-MM-DD'), moment(dataFim, 'YYYY-MM-DD')];
		}
		return null;
	};

	useEffect(() => {
		if (dataInicio && dataFim) {
			const recipientId = unidadeRecipienteId;
			setIsGlobalLoading(true);

			servicePagarMeV4
				.operacoesNew(
					recipientId,
					dataFim.toISOString(),
					dataInicio.toISOString(),
				)
				.then((response) => {
					const sortedData = response.data.sort((a, b) => {
						return a?.operacao?.date_created > b?.operacao?.date_created
							? -1
							: a?.operacao?.date_created < b?.operacao?.date_created
								? 1
								: 0;
					});
					setDadosPagarMeV4(sortedData);
					setData(sortedData);
				})
				.catch((error) => console.log(error))
				.finally(() => setIsGlobalLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidadeRecipienteId, dataInicio, dataFim]);

	const dadosExportExcel = useDadosExportExcel({ data });

	const filterTransactions = (operacaoId) => {
		return dadosPagarMeV4.filter(item => item.operacao.oid === operacaoId);
	};

	const selectUnidades = unidade?.map((unidade) => (
		<Option key={unidade.id} value={unidade?.identificadorPagarMeV4}>
			{unidade.descricao}
		</Option>
	));

	return (
		<div className="container">
			<NewMenu selecionado={1} />
			<div className="dashboard gap-20" style={{ height: '100vh', overflowY: 'auto', paddingTop: 50 }}>
				<div className="flex flex-column gap-10">
					<h3 className="title">Conciliação bancária</h3>
					<div className="space-between">
						<div className="flex gap-20">
							<div className="flex flex-row items-end" style={{ marginBottom: 10 }}>
								<div className="datePicker">
									<span>Período</span>
									<br />
									<RangePicker
										value={getPeriodo()}
										onChange={(values) => {
											setDataInicio(values && values[0]);
											setDataFim(values && values[1]);
										}}
										format={'DD-MM-YYYY'}
									/>
								</div>
							</div>
							<div>
								<span>Unidades</span>
								<br />
								<Select
									showSearch
									allowClear
									style={filtroStyle}
									defaultValue={unidadeRecipienteId}
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onChange={(e) => setUnidadeRecipienteId(e)}
								>
									<Option value={process.env.REACT_APP_HAPPYCODE_RECIPIENT_ID}>Sede</Option>
									{selectUnidades}
								</Select>
							</div>
						</div>
						<div>
							<span style={{ fontSize: '0.8em' }}>Excel</span>
							<br />
							<ExportToExcel apiData={dadosExportExcel} fileName={'Conciliação-bancaria'} />
						</div>
					</div>
				</div>

				<Table
					dataSource={dadosPagarMeV4?.filter(item => !item.operacao.oid)}
					columns={TableColumnsOperacao()}
					pagination={false}
					rowClassName={(record) =>
						record.operacao.movement_object.object === 'transfer' ? 'saque' : ''
					}
					scroll={{ x: '100%' }}
					style={{ whiteSpace: "nowrap" }}
					rowKey={(record) => record.operacao.id}
					expandable={{
						expandedRowRender: (record) => (
							<Table
								dataSource={filterTransactions(record.operacao.id)}
								columns={TableColumns()}
								pagination={false}
								rowKey={(transaction) => transaction.operacao.id}
							/>
						),
					}}
				/>
			</div>
		</div>
	);
}

export default RelConciliacaoBancariaSedeNew;
