import { CloseOutlined, UploadOutlined } from '@mui/icons-material';
import { Button, Image, Input, Radio, Space, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ContadorCaracteres from '../../components/ContadorCaracteres';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import { IPushNotificacaoDTO } from '../../models/happy-code-api.model';
import { useLearnPushService } from '../../services/learn-push';
import { useNotificationService } from '../../services/notification.service';
import { token } from '../../util/store';

const LearnPushCreate: React.FunctionComponent = () => {
	const { setIsGlobalLoading, unidade } = useGlobalContext();
	const notification = useNotificationService();
	const learnPushService = useLearnPushService();
	const [learnPush, setLearnPush] = useState<IPushNotificacaoDTO>();
	const [validaTipoArquivo, setValidaTipoArquivo] = useState<boolean>(false);
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);
	const { idPush } = useParams<any>();

	const findLearnPush = () => {
		setIsGlobalLoading(true);
		learnPushService.findById({ id: parseInt(idPush) })
			.then((response) => {
				setLearnPush(response.data);
			}).finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		if (idPush) {
			findLearnPush();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submit = () => {
		setIsGlobalLoading(true);
		if (!idPush) {
			learnPush.unidade = unidade;
			learnPushService.createPush(learnPush)
				.then(() => {
					notification({
						description: `Push cadastrado com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/push-learn');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		} else {
			learnPush.unidade = unidade;
			learnPushService.patchPush(learnPush)
				.then(() => {
					notification({
						description: `Push editado com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/push-learn');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		}
	};

	const submitValidationFields = () => {
		if (!learnPush?.titulo) {
			notification({
				description: `Por favor, preencha o campo título.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!learnPush?.subTitulo) {
			notification({
				description: `Por favor, preencha o campo subtítulo.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!learnPush?.mensagem) {
			notification({
				description: `Por favor, preencha o campo mensagem.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!learnPush?.urlImagem) {
			notification({
				description: `Por favor, selecione uma imagem para o push corretamente.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!learnPush?.url) {
			notification({
				description: `Por favor, preencha o campo url.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		submit();
	};

	const onChange = ({ file }: UploadChangeParam) => {
		const { status } = file;

		if (status === 'done') {
			notification({
				description: `${file.name} upload feito com sucesso`,
				type: 'success',
				message: 'Sucesso!',
			});
			const response = file.response;
			setLearnPush({ ...learnPush, urlImagem: response });
		} else if (status === 'error') {
			notification({
				description: `${file.name} falha ao fazer o upload do arquivo `,
				type: 'error',
				message: 'Erro!',
			});
		} else if (status === 'removed') {
			setLearnPush({ ...learnPush, urlImagem: null });
			notification({
				description: `${file.name} removida com sucesso`,
				type: 'success',
				message: 'Sucesso!',
			});
		}
	};

	const beforeUpload = (file: UploadFile) => {
		const isValidType = file !== undefined && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/x-icon' || file.type === 'image/webp');
		if (!isValidType) {
			notification({
				description: `Apenas arquivos PNG, JPG, WEBP e ICO são permitidos.`,
				type: 'error',
				message: 'Erro!',
			});
			return false;
		}

		if (file.size > 5 * 1024 * 1024) {
			notification({
				description: `O tamanho do arquivo deve ser inferior a 5MB.`,
				type: 'error',
				message: 'Erro!',
			});
			return false;
		}

		const maxWidth = 2000;
		const maxHeight = maxWidth / 2;

		return new Promise<boolean>((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file as unknown as Blob);
			reader.onload = () => {
				const img = new window.Image();
				img.src = reader.result as string;
				img.onload = () => {
					const { width, height } = img;

					if (width > maxWidth || height > maxHeight) {
						notification({
							description: `A imagem excede as dimensões permitidas: ${maxWidth}x${maxHeight}px.`,
							type: 'error',
							message: 'Erro!',
						});
						resolve(false);
					} else {
						resolve(true);
					}
				};
			};
		});
	};

	const limparArquivo = () => {
		setLearnPush({ ...learnPush, urlImagem: null })
	}

	return (
		<div className="container-fondo">
			<Header />
			<div className="franquia" style={{ left: 0 }}>
				<div className="user-row">
					<div className="card-form">
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<h3 id="taxas-h3" style={{ marginBottom: 10 }}>
								Insira informações para {idPush ? 'edição' : 'criação'} de Push
							</h3>
							<Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined />} />
						</div>
						<div className="input-box">
							<label>Título</label>
							<Input
								defaultValue={learnPush?.titulo}
								value={learnPush?.titulo}
								id="titulo"
								maxLength={100}
								onChange={(e) => setLearnPush({ ...learnPush, titulo: e.target.value })}
							/>
						</div>
						<div className="input-box">
							<label>SubTítle</label>
							<Input
								defaultValue={learnPush?.subTitulo}
								value={learnPush?.subTitulo}
								id="subTitulo"
								maxLength={100}
								onChange={(e) => setLearnPush({ ...learnPush, subTitulo: e.target.value })}
							/>
						</div>
						<div className="input-box">
							<label>Mensagem</label>
							<TextArea
								id="descricao"
								maxLength={200}
								defaultValue={learnPush?.mensagem}
								value={learnPush?.mensagem}
								onChange={(e) => setLearnPush({ ...learnPush, mensagem: e.target.value })} />
							<ContadorCaracteres
								contador={learnPush?.mensagem?.length}
								limite={4000} />
						</div>
						<div className="input-box">
							<label htmlFor="checkedIcone">Tipo do arquivo</label>
							<Radio.Group
								defaultValue={validaTipoArquivo}
								value={validaTipoArquivo}
								onChange={(e) => setValidaTipoArquivo(e.target.value)}
							>
								<Space direction="vertical">
									<Radio value={false}>Arquivo</Radio>
									<Radio value={true} onClick={limparArquivo}>Link</Radio>
								</Space>
							</Radio.Group>
						</div>
						{!validaTipoArquivo ?
							<div className="input-box">
								<label htmlFor="arquivo">Upload arquivo</label>
								<div style={{ textAlign: 'center', width: '100%' }}>
									<div>{learnPush?.urlImagem && <Image src={learnPush.urlImagem} alt="imagem da notificação" width={200} />}</div>
									<Upload
										headers={{ Authorization: `Bearer ${token()}` }}
										action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload/link`}
										onChange={onChange}
										maxCount={1}
										beforeUpload={beforeUpload}
									>
										<Button
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												marginBottom: '1rem',
											}}
										>
											<UploadOutlined style={{ fontSize: '20px', marginBottom: '4px' }} />
											<span>Clique para fazer upload da imagem</span>
										</Button>
									</Upload>
								</div>
							</div>
							:
							<div className="input-box gap-20">
								<label htmlFor="linkIcone">Informar link</label>
								<div className="w-100 flex justify-center">{learnPush?.urlImagem && <Image src={learnPush.urlImagem} alt="imagem da notificação" width={200} />}</div>
								<Input
									id="linkIcone"
									defaultValue={learnPush?.urlImagem}
									value={learnPush?.urlImagem}
									onChange={(e) => setLearnPush({ ...learnPush, urlImagem: e.target.value.trim() })} />
							</div>
						}

						<div className="input-box">
							<label>URL</label>
							<Input
								defaultValue={learnPush?.url}
								value={learnPush?.url}
								id="url"
								maxLength={100}
								onChange={(e) => setLearnPush({ ...learnPush, url: e.target.value })}
							/>
						</div>
						<div className="botoes" style={{ marginTop: 10 }}>
							<Link to={`/push-learn`}>
								<Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>
									Voltar
								</Button>
							</Link>
							<Button style={{ width: 345 }} type="primary" id="proximo" className="button-primary" onClick={submitValidationFields}>
								Salvar
							</Button>
						</div>
					</div>
				</div>
			</div>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a operação?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2={`/insignia`}
			/>
		</div>
	);
};

export default LearnPushCreate;
