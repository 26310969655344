import http from '../helpers/http-common';
import { IPushNotificacaoCleanDTO, IPushNotificacaoDTO, IPushNotificacaoFullDTO } from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useLearnPushService = () => {
	const resourceURL = '/push-notificacao';

	const createPush = (body): Promise<IAxiosResponseCustom<IPushNotificacaoDTO>> =>
		http.post(`${resourceURL}/create-push`, body);

	const patchPush = (body): Promise<IAxiosResponseCustom<IPushNotificacaoDTO>> =>
		http.patch(`${resourceURL}/patch-push`, body);

	const pushNotification = (id: number, idUnidade?: number): Promise<IAxiosResponseCustom<IPushNotificacaoDTO>> =>
		http.post(`${resourceURL}/push/${id}${idUnidade ? `/${idUnidade}` : ''}`);

	return {
		...baseFindList<IPushNotificacaoCleanDTO, IPushNotificacaoDTO, IPushNotificacaoFullDTO>(resourceURL),
		...baseFind<IPushNotificacaoCleanDTO, IPushNotificacaoDTO, IPushNotificacaoFullDTO>(resourceURL),
		...baseFindList<IPushNotificacaoCleanDTO, IPushNotificacaoDTO, IPushNotificacaoFullDTO>(resourceURL),
		...baseFindById<IPushNotificacaoCleanDTO, IPushNotificacaoDTO, IPushNotificacaoFullDTO>(resourceURL),
		...baseCreate<IPushNotificacaoCleanDTO, IPushNotificacaoDTO, IPushNotificacaoFullDTO>(resourceURL),
		...baseUpdate<IPushNotificacaoCleanDTO, IPushNotificacaoDTO, IPushNotificacaoFullDTO>(resourceURL),
		createPush,
		patchPush,
		pushNotification
	};
};
