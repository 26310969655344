import { Button, DatePicker } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu';
import { useGlobalContext } from '../../../context/GlobalContext';
import { IOperacoesPagarMeV4DTO } from '../../../models/relatorio.model';
import { usePagarMeV4Service } from '../../../services/pagarmeV4.service';
import { ExportToExcel } from '../../../util/exportToExcel';
import { formatMoney } from '../../../util/format';
import './style.css';

const { RangePicker } = DatePicker;

function RelConciliacaoBancariaSede() {
	const { setIsGlobalLoading, unidade } = useGlobalContext();
	const [dadosPagarMeV4, setDadosPagarMeV4] =
		useState<IOperacoesPagarMeV4DTO[]>();
	const [dataInicio, setDataInicio] = useState<moment.Moment>();
	const [dataFim, setDataFim] = useState<moment.Moment>();
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(15);

	const servicePagarMeV4 = usePagarMeV4Service();

	const setaDataInicial = () => {
		const dataFimQuandoAbreATela = moment();
		setDataFim(dataFimQuandoAbreATela);

		const dataInicioQuandoAbreATela = moment().subtract(7, 'days');
		setDataInicio(dataInicioQuandoAbreATela);
	};

	useEffect(() => {
		setaDataInicial();
	}, [unidade.id]);

	const getPeriodo = (): any => {
		if (dataInicio && dataFim) {
			return [moment(dataInicio, 'YYYY-MM-DD'), moment(dataFim, 'YYYY-MM-DD')];
		}
		return null;
	};

	useEffect(() => {
		const recipientId = process.env.REACT_APP_HAPPYCODE_RECIPIENT_ID;
		if (dataInicio && dataFim) {
			setIsGlobalLoading(true);
			servicePagarMeV4
				.operacoes(
					recipientId,
					page,
					dataFim?.toDate().getTime(),
					dataInicio?.toDate().getTime(),
					count
				)
				.then((response) => setDadosPagarMeV4(response.data))
				.catch((error) => console.log(error))
				.finally(() => setIsGlobalLoading(false));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, dataInicio, dataFim]);

	const pageChangeMais = (): number => {
		setPage(page + 1);
		return page;
	};

	const pageChangeMenos = (): number => {
		setPage(page - 1);
		return page;
	};

	useEffect(() => {
		dadosPagarMeV4?.sort(function (a, b) {
			return a?.operacao?.date_created > b?.operacao?.date_created
				? -1
				: a?.operacao?.date_created < b?.operacao?.date_created
					? 1
					: 0;
		});
	}, [dadosPagarMeV4]);

	return (
		<div className="container">
			<NewMenu selecionado={1} />
			<div
				className="dashboard"
				style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
			>
				<h3 className="title">Conciliação bancaria</h3>
				<div className="space-between">
					<div className="flex flex-row items-end" style={{ marginBottom: 10 }}>
						<div className="datePicker">
							<span>Período</span>
							<br></br>
							<RangePicker
								value={getPeriodo()}
								onChange={(values) => {
									setDataInicio(values && values[0]);
									setDataFim(values && values[1]);
								}}
								format={'DD-MM-YYYY'}
							/>
						</div>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Excel</span>
						<br />
						<ExportToExcel apiData={dadosPagarMeV4} fileName={'Conciliação-bancaria'} />
					</div>
				</div>

				<div className="scroll-tabelas">
					<table id="tableConciliacao">
						<thead>
							<tr id="top">
								<td id="colunas">Tipo</td>
								<td id="colunas">Data liberado</td>
								<td id="colunas">Valor Cobrança</td>
								<td id="colunas">Valor Saque</td>
								<td id="colunas">Status</td>
								<td id="colunas">Previsão saque</td>
								<td id="colunas">Saque realizado</td>
								<td id="colunas">Data pagamento</td>
								<td id="colunas">Valor sede</td>
								<td id="colunas">Taxa</td>
								<td id="colunas">Valor HUB</td>
								<td id="colunas">Líquido HUB</td>
								<td id="colunas">Parcela</td>
								<td id="colunas">Nome responsável</td>
								<td id="colunas">Nome aluno</td>
								<td id="colunas">HUB</td>
							</tr>
						</thead>
						<tbody>
							{dadosPagarMeV4?.map((dados, index) => (
								<tr key={index}>
									<td
										id="colunas"
										className={
											dados?.operacao?.type == 'transfer' ? 'saque' : ''
										}
									>
										{dados?.operacao?.type == 'transfer'
											? 'Saque'
											: dados?.operacao?.type == 'payable'
												? 'Pagamento'
												: dados?.operacao?.type == 'fee_collection'
													? 'Serviços de gateway'
													: 'falha'}
									</td>
									<td
										id="colunas"
										className={
											dados?.operacao?.type == 'transfer' ? 'saque' : ''
										}
									>
										{moment(dados?.operacao?.date_created).format('DD/MM/YYYY')}
									</td>
									<td
										id="colunas"
										className={
											dados?.operacao?.type == 'transfer' ? 'saque' : ''
										}
									>
										{dados.vlCobranca ? formatMoney(dados.vlCobranca) : '-'}
									</td>
									<td
										id="colunas"
										className={
											dados?.operacao?.type == 'transfer' ? 'saquePago' : ''
										}
									>
										{formatMoney(
											dados?.operacao?.movement_object?.amount / 100
										)}
									</td>
									<td
										id="colunas"
										className={
											dados?.operacao?.type == 'transfer' ? 'saque' : ''
										}
									>
										{dados?.operacao?.movement_object?.status == 'processing'
											? 'Processando'
											: dados?.operacao?.movement_object?.status == 'paid'
												? 'Pago'
												: dados?.operacao?.movement_object?.status ==
													'transferred'
													? 'Transferido'
													: dados?.operacao?.type == 'fee_collection'
														? 'Cobrança'
														: 'Falha'}
									</td>
									<td
										id="colunas"
										className={
											dados?.operacao?.type == 'transfer' ? 'saque' : ''
										}
									>
										{dados?.operacao?.movement_object?.funding_estimated_date
											? moment(
												dados?.operacao?.movement_object
													?.funding_estimated_date
											).format('DD/MM/YYYY')
											: '-'}
									</td>
									<td
										id="colunas"
										className={
											dados?.operacao?.type == 'transfer' ? 'saque' : ''
										}
									>
										{dados?.operacao?.movement_object?.funding_date
											? moment(
												dados?.operacao?.movement_object?.funding_date
											).format('DD/MM/YYYY')
											: '-'}
									</td>
									<td
										id="colunas"
										className={
											dados?.operacao?.type == 'transfer' ? 'saque' : ''
										}
									>
										{dados?.dhPagamento
											? moment(dados?.dhPagamento).format('DD/MM/YYYY')
											: '-'}
									</td>
									<td
										id="colunas"
										className={
											dados?.operacao?.type == 'transfer' ? 'saque' : ''
										}
									>
										{formatMoney(dados?.vlSede ? dados?.vlSede : 0)}
									</td>
									<td
										id="colunas"
										className={
											dados?.operacao?.type == 'transfer' ? 'saque' : ''
										}
									>
										{dados?.operacao?.movement_object?.fee
											? formatMoney(dados?.operacao?.movement_object?.fee / 100)
											: '-'}
									</td>
									<td
										id="colunas"
										className={
											dados?.operacao?.type == 'transfer' ? 'saque' : ''
										}
									>
										{formatMoney(dados?.vlFranquia ? dados?.vlFranquia : 0)}
									</td>
									<td
										id="colunas"
										className={
											dados?.operacao?.type == 'transfer'
												? 'saqueValor'
												: dados?.operacao?.type == 'payable'
													? 'saquePagoBranco'
													: ''
										}
									>
										{dados?.operacao?.movement_object?.fee
											? formatMoney(
												dados?.vlFranquia -
												dados?.operacao?.movement_object?.fee / 100
											)
											: '-'}
									</td>
									<td
										id="colunas"
										className={
											dados?.operacao?.type == 'transfer' ? 'saque' : ''
										}
									>
										{dados?.operacao?.movement_object?.installment
											? dados?.operacao?.movement_object?.installment
											: '-'}
									</td>
									<td
										id="colunas"
										className={
											dados?.operacao?.type == 'transfer' ? 'saque' : ''
										}
									>
										{dados?.nomeResponsavel ? dados?.nomeResponsavel : '-'}
									</td>
									<td
										id="colunas"
										className={
											dados?.operacao?.type == 'transfer' ? 'saque' : ''
										}
									>
										{dados?.nomeAluno ? dados?.nomeAluno : '-'}
									</td>
									<td id="colunas">{dados?.nomeUnidade}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="botoesConciliacao">
					<Button
						className="botaoConciliacao"
						onClick={pageChangeMenos}
						disabled={page === 1}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-arrow-left-circle"
							viewBox="0 0 16 16"
						>
							<path
								fillRule="evenodd"
								d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
							/>
						</svg>
					</Button>
					<p>{page}</p>
					<Button
						className="botaoConciliacao"
						onClick={pageChangeMais}
						disabled={dadosPagarMeV4?.length < 15}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-arrow-right-circle"
							viewBox="0 0 16 16"
						>
							<path
								fillRule="evenodd"
								d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
							/>
						</svg>
					</Button>
				</div>
				<div>
					<h3 className="title">Glossário</h3>
					<li>Saque: Valor de saque que será enviado a conta.</li>
					<li>
						Pagamento: Valor que foi liberado e está disponível para saque.
					</li>
					<li>Data liberado: Data que foi liberado pagamento/saque.</li>
					<li>Valor saque: Valor do saque bruto.</li>
					<li>Status: Status atual da transação.</li>
					<li>Previsão saque: Previsão do depósito pelo pagar.me.</li>
					<li>Saque realizado: Dia que foi realizado o saque.</li>
					<li>Data pagamento: Dia que a cobrança foi paga pelo responsável.</li>
					<li>Valor sede: Valor da licença.</li>
					<li>Taxa: Taxa paga na transação ao pagar.me.</li>
					<li>Valor HUB: Valor do HUB bruto, sem subtrair as taxas.</li>
					<li>Liquido HUB: Valor do HUB após subtrair as taxas</li>
					<li>
						Parcela: Se for cartão de crédito este campo vai informar qual a
						parcela.
					</li>
					<li>Nome responsável: Nome do responsável</li>
					<li>Nome aluno: Nome do aluno</li>
				</div>
			</div>
		</div>
	);
}

export default RelConciliacaoBancariaSede;
