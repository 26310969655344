/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, SetStateAction, useContext, useEffect, useState } from 'react';
import { AuthEnum } from '../enum/auth.enum';
import { Auth } from '../models/auth.model';
import { ITipoUnidadeCleanDTO, IUnidadeCleanDTO, IUserAuthDTO } from '../models/happy-code-api.model';
import { getObject, setObject } from '../util/store';
import { useTipoUnidadeService } from '../services/tipo-unidade.service';

//Tipando as Props do contexto
interface PropsUserContext {
	isGlobalLoading: boolean;
	usuario: IUserAuthDTO;
	unidade: IUnidadeCleanDTO;
	tipoUnidade: ITipoUnidadeCleanDTO;
	token: Auth;
	setToken: React.Dispatch<React.SetStateAction<Auth>>;
	setUsuario: React.Dispatch<React.SetStateAction<IUserAuthDTO>>;
	setUnidade: React.Dispatch<React.SetStateAction<IUnidadeCleanDTO>>;
	setIsGlobalLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setTipoUnidade: React.Dispatch<React.SetStateAction<ITipoUnidadeCleanDTO>>;
}

//Valor default do contexto
const DEFAULT_VALUE: PropsUserContext = {
	isGlobalLoading: false,
	usuario: {
		id: null,
		usuario: '',
		login: '',
		perfil: '',
		funcao: '',
		active: true,
		arquivoUrl: '',
		arquivo: null,
		enabled: true,
		password: '',
		username: '',
		credentialsNonExpired: true,
		authorities: null,
		accountNonExpired: true,
		accountNonLocked: true,
		acessoB2b: false,
		acessoB2c: false,
		acessoComercial: false,
		instrutorAcademy: false,
	},
	unidade: {
		id: null,
		codigo: '',
		descricao: '',
		cnpj: '',
		inscricaoEstadual: '',
		dataAbertura: null,
		dataContrato: null,
		dataRenovacaoContrato: null,
		dataTerminoContrato: null,
		dataInicioFerias: null,
		dataTerminoFerias: undefined,
		pendencia: true,
		identificadorPagarMe: null,
		identificadorEnotas: null,
		identificadorPagarMeV4: null,
		identificadorPagarMePos: null,
		identificadorPagarMeEscola: null,
		identificadorDevice: null,
		facebookId: '',
		email: '',
		telefone: '',
		telefoneComandante: '',
		telefoneGuerreiro: '',
		razaoSocial: '',
		capital: null,
		//unidadeAtuacao: "",
		//unidadeCaracteristica: "",
		diaFuncionamento: '',
		dsInstagram: '',
		dsLpHub: '',
		horaAbertura: null,
		horaFechamento: null,
		horaAulaInicio: null,
		horaAlmocoInicio: null,
		horaAlmocoFim: null,
		intervalo: null,
		flIntervalo: null,
		ativo: true,
		dtVencimento: null,
	},
	tipoUnidade: {} as ITipoUnidadeCleanDTO,

	token: undefined,
	//logout: () => { }, //função de inicialização
	setToken: () => { }, //função de inicialização
	setUsuario: () => { }, //função de inicialização
	setUnidade: () => { }, //função de inicialização
	setIsGlobalLoading: () => { },

	setTipoUnidade: () => { },
};

const nameStorageLoginUnidade = '@HappyCode/login/unidade';

//criando nosso contexto UserContext
const GlobalContext = createContext<PropsUserContext>(DEFAULT_VALUE);

/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const GlobalContextProvider: React.FC = (props: any) => {
	const [isGlobalLoading, setIsGlobalLoading] = useState(DEFAULT_VALUE.isGlobalLoading);
	const [usuario, setUsuarioGlobal] = useState(DEFAULT_VALUE.usuario);
	const [unidade, setUnidadeGlobal] = useState<IUnidadeCleanDTO>(() => getObject(nameStorageLoginUnidade) || DEFAULT_VALUE.unidade);
	const [token, setTokenGlobal] = useState(DEFAULT_VALUE.token);
	const [tipoUnidade, setTipoUnidade] = useState<ITipoUnidadeCleanDTO>(DEFAULT_VALUE.tipoUnidade);
	const serviceTipoUnidade = useTipoUnidadeService();

	const setToken = (token) => {
		setObject(AuthEnum.TOKEN_KEY, token);
		setTokenGlobal(token);
	};
	const setUsuario = (usuario) => {
		setObject(AuthEnum.USER_KEY, usuario);
		setUsuarioGlobal(usuario);
	};
	const setUnidade = (unidade) => {
		setObject(nameStorageLoginUnidade, unidade);
		setUnidadeGlobal(unidade);
	};

	const findTipoUnidade = () => {
		serviceTipoUnidade.findTipoUnidadeById(unidade?.id)
			.then((response) => {
			setTipoUnidade(response.data);
		});
	};

	useEffect(() => {
		if (!unidade?.id) return;
		findTipoUnidade();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade?.id])

	useEffect(() => {
		const usuario = getObject(AuthEnum.USER_KEY);
		if (usuario) {
			setUsuarioGlobal(usuario);
		}

		const unidade = getObject(nameStorageLoginUnidade);
		if (unidade) {
			setUnidadeGlobal(unidade);
		}

		const token = getObject(AuthEnum.TOKEN_KEY);
		if (token) {
			setTokenGlobal(token);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	return (
		<GlobalContext.Provider
			value={{
				isGlobalLoading,
				usuario,
				unidade,
				token,
				setToken,
				setUsuario,
				setUnidade,
				setIsGlobalLoading,
				setTipoUnidade,
				tipoUnidade,
			}}
		>
			{props.children}
		</GlobalContext.Provider>
	);
};

export const useGlobalContext = (): PropsUserContext => {
	const context = useContext(GlobalContext);
	if (!context) throw new Error('useGlobalContext must be used within a GlobalContextProvider');
	return context;
};
