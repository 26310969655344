import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../assets/img/login/logo-space.png';
import { useGlobalContext } from '../../context/GlobalContext';

import ArrowLeftOutlined from '@ant-design/icons/lib/icons/ArrowLeftOutlined';
import { useUsuarioService } from '../../services/usuario.service';
import '../Login/style.css';
import { useNotificationService } from './../../services/notification.service';
import './style.css';

interface TInputs {
  email: string;
}

export const Senha: React.FunctionComponent = () => {
  const { register, handleSubmit } = useForm<TInputs>();
  const notification = useNotificationService();
  const userService = useUsuarioService();
  const history = useHistory();
  const { setIsGlobalLoading } = useGlobalContext()

  const onRecuperaSenha = (data: TInputs) => {
    setIsGlobalLoading(true);
    userService.recuperaSenha(data.email)
      .then(() => {
        notification({ message: "Sucesso!", description: `Por favor, acesse o seu email ${data.email} e clique no link de recuperação.`, type: 'info' });
        history.push('');
      }).finally(() => setIsGlobalLoading(false));
  };

  return (
    <form onSubmit={handleSubmit(onRecuperaSenha)} className="container-login" style={{ backgroundColor: 'var(--white-color' }}>

      <div className="formulario-box">
        <div className="formulario esqueceu-senha">
          <div className="arrow">
            <Link to="/">
              <Button className="button-second-voltar" icon={<ArrowLeftOutlined rev={undefined} />} >Voltar</Button>
            </Link>
          </div>

          <img src={logo} alt="Logo-Space" style={{ width: '60%', maxWidth: '300px' }} />

          <h3>Recuperação de senha</h3>

          <div className="input">
            <Unicons.UilEnvelopeAlt size="20" color="#6A7C96" className="iconForm" />
            <input {...register('email', { required: true })} type="text" placeholder="Email"  />
          </div>
          <button type="submit" className="button-second button-login">
          <span style={{ color: '#0C306C' }}>Enviar</span>
          </button>
        </div>
      </div>
    </form>
  );
}