import { QuestionCircleOutlined } from '@ant-design/icons';
import { DateRangeOutlined, SearchOutlined } from '@mui/icons-material';
import { Button, DatePicker, Input, Space, TableColumnsType, TableColumnType, Tooltip } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { get } from 'lodash';
import moment from 'moment';
import { useState } from "react";
import Highlighter from 'react-highlight-words';
import { IOperacoesPagarMeV4DTO } from "../../../../models/relatorio.model";
import { formatDataRelatorio, formatMoney, formattedDate } from "../../../../util/format";
import useTranslate from './hooks/use-translation.hook';

const TableColumnsOperacao = (): TableColumnsType<IOperacoesPagarMeV4DTO> => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [filteredRange, setFilteredRange] = useState<[moment.Moment, moment.Moment] | null>(null);
    const { translate } = useTranslate();

    const handleDateChange = (dates: [moment.Moment, moment.Moment] | null, setSelectedKeys: (keys: string[]) => void) => {
        setFilteredRange(dates);
        if (dates) {
            setSelectedKeys([dates[0].toISOString(), dates[1].toISOString()]);
        } else {
            setSelectedKeys([]);
        }
    };

    const handleSearch = (selectedKeys: string[], confirm: FilterDropdownProps['confirm'], dataIndex: string) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
        setFilteredRange(null);
    };

    const getColumnDateProps = (dataIndex: string[]): TableColumnType<IOperacoesPagarMeV4DTO> => ({
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <DatePicker.RangePicker
                    className="mr-10"
                    onChange={(dates) => handleDateChange(dates, setSelectedKeys)}
                    value={filteredRange}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, height: "auto" }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" className="w-90">
                        Resetar
                    </Button>
                </Space>
            </div>
        ),

        filterIcon: (filtered: boolean) => (
            <DateRangeOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),

        onFilter: (_value, record) => {
            const dateValue = moment(get(record, dataIndex));
            if (!filteredRange || !dateValue.isValid()) {
                return false;
            }

            const [start, end] = filteredRange;
            return dateValue.isBetween(start, end, 'days', '[]');
        },

        render: (_value, record) => {
            const dateValue = get(record, dataIndex);
            return formattedDate(dateValue);
        },
    });




    const getColumnSearchProps = (dataIndex: string): TableColumnType<IOperacoesPagarMeV4DTO> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, height: 'auto' }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" className="w-90">
                        Resetar
                    </Button>
                </Space>
            </div>
        ),

        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),

        onFilter: (value, record) => {
            const recordValue = get(record, dataIndex);
            return recordValue?.toString()?.toLowerCase()?.includes((value as string).toLowerCase());
        },

        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
        });
        
        
        const columns: TableColumnsType<IOperacoesPagarMeV4DTO> = [
            {
            title: (
                <div>
                    Data de Criação
                    <Tooltip title="Data que foi criada a Operação.">
                        <QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
                    </Tooltip>
                </div>
            ),
            dataIndex: ['operacao', 'date_created'],
            align: "center",
            key: 'date_created',
            sorter: (a, b) => moment(a.operacao.date_created).unix() - moment(b.operacao.date_created).unix(),
            ...getColumnDateProps(['operacao', 'date_created']),
            render(_value, record, _index) {
                return formatDataRelatorio(record.operacao.date_created);
            },
        },
        {
            title: (
                <div>
                    Data Previsão
                    <Tooltip title="Previsão do depósito pelo pagar.me.">
                        <QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
                    </Tooltip>
                </div>
            ),
            dataIndex: ['operacao', 'movement_object', 'funding_estimated_date'],
            align: "center",
            key: 'funding_estimated_date',
            sorter: (a, b) => moment(a.operacao.movement_object.funding_estimated_date).unix() - moment(b.operacao.movement_object.funding_estimated_date).unix(),
            ...getColumnDateProps(['operacao', 'movement_object', 'funding_estimated_date']),
            render(_value, record, _index) {
                return formatDataRelatorio(record.operacao.movement_object.funding_estimated_date);
            },
        },
            {
                title: (
                    <div>
                        Data de Transferido
                        <Tooltip title="Dia que foi realizado o saque.">
                            <QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
                        </Tooltip>
                    </div>
                ),
                dataIndex: ['operacao', 'movement_object', 'funding_date'],
                align: "center",
                key: 'funding_date',
                sorter: (a, b) => moment(a.operacao.movement_object.funding_date).unix() - moment(b.operacao.movement_object.funding_date).unix(),
                ...getColumnDateProps(['operacao', 'movement_object', 'funding_date']),
                render(_value, record, _index) {
                    return formatDataRelatorio(record.operacao.movement_object.funding_date);
                },
            },
        {
            title: (
                <div>
                    Valor
                    <Tooltip title="Valor de saque que será enviado à conta.">
                        <QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
                    </Tooltip>
                </div>
            ),
            dataIndex: ['operacao', 'movement_object', 'amount'],
            align: "center",
            key: 'amount',
            sorter: (a, b) => a.operacao.movement_object.amount - b.operacao.movement_object.amount,
            render: (text, record) => {
                const valor = text / 100;
                const taxa = record.operacao.movement_object.fee / 100;
                const valorFinal = valor - taxa;
                return formatMoney(valorFinal) || "-";
            },
        },
        {
            title: (
                <div>
                    Operação
                    <Tooltip title="Tipo de Operação.">
                        <QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
                    </Tooltip>
                </div>
            ),
            dataIndex: ['operacao', 'type'],
            align: "center",
            key: 'type',
            sorter: (a, b) => a.operacao.type?.localeCompare(b.operacao.type),
            render(_value, record, _index) {
                return translate(record.operacao.type);
            },
        },
        {
            title: (
                <div>
                    Status da Operação
                    <Tooltip title="Status atual da transação.">
                        <QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
                    </Tooltip>
                </div>
            ),
            dataIndex: ['operacao', 'status'],
            align: "center",
            key: 'status',
            sorter: (a, b) => a.operacao.status?.localeCompare(b.operacao.status),
            render(_value, record, _index) {
                return translate(record.operacao.status);
            },
        },
        {
            title: 'Objeto de Movimento',
            dataIndex: ['operacao', 'movement_object', 'object'],
            align: "center",
            key: 'movement_object',
            sorter: (a, b) => a.operacao.movement_object.object?.localeCompare(b.operacao.movement_object.object),
            render(_value, record, _index) {
                return translate(record.operacao.movement_object.object);
            },
        },
        {
            title: 'Status do Movimento',
            dataIndex: ['operacao', 'movement_object', 'status'],
            align: "center",
            key: 'movement_status',
            sorter: (a, b) => a.operacao.movement_object.status?.localeCompare(b.operacao.movement_object.status),
            render(_value, record, _index) {
                return translate(record.operacao.movement_object.status);
            },
        },
        {
            title: (
                <div>
                    ID da Operação
                    <Tooltip title="Identificador da Operação.">
                        <QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
                    </Tooltip>
                </div>
            ),
            dataIndex: ['operacao', 'id'],
            align: "center",
            key: 'id',
            sorter: (a, b) => a.operacao.id - b.operacao.id,
            ...getColumnSearchProps('operacao.id'),
            render(_value, record, _index) {
                return record.operacao.id;
            },
        },
    ];

    return columns;
};

export default TableColumnsOperacao;
