import { useMemo } from 'react';
import { IOperacoesPagarMeV4DTO } from '../../../../../models/relatorio.model';
import { formattedDate } from '../../../../../util/format';
import useTranslate from './use-translation.hook';

interface UseDadosExportExcelProps {
    data: IOperacoesPagarMeV4DTO[];
}

export const useDadosExportExcel = ({ data }: UseDadosExportExcelProps) => {
    const { translate } = useTranslate();
    return useMemo(() => {
        const arrayDados = [];
        const totals: Record<string, string> = {};

        if (data) {
            data.forEach((dado) => {
                arrayDados.push({
                    DATA_CRIACAO: dado.operacao.date_created,
                    DT_PAGAMENTO: formattedDate(dado.dhPagamento) || '00/00/000',
                    DATA_PREVISAO: formattedDate(dado.operacao.movement_object.funding_estimated_date) || '00/00/000',
                    DATA_TRANSFERIDO: formattedDate(dado.operacao.movement_object.funding_date) || '00/00/000',
                    ALUNO: dado.nomeAluno,
                    RESPONSAVEL_FINANCEIRO: dado.nomeResponsavel,
                    TIPO_PAGAMENTO: translate(dado.operacao.movement_object.payment_method),
                    VL_COBRANCA: dado.vlCobranca,
                    VL_PAGO: dado.vlPago,
                    VL_SEDE: dado.vlSede,
                    VL_FRANQUIA: dado.vlFranquia,
                    VL_TAXA: dado.operacao.movement_object.fee,
                    VALOR: dado.operacao.movement_object.amount - dado.operacao.movement_object.fee,
                    PARCELA: dado.parcela,
                    PARCELA_FINANCEIRO: dado.parcelaFinanceiro,
                    UNICA: dado.flUnica ? "Sim" : "Não",
                    OPERACAO: translate(dado.operacao.type),
                    OPERACAO_STATUS: translate(dado.operacao.status),
                    OBJETO_DE_MOVIMENTO: translate(dado.operacao.movement_object.object),
                    STATUS_DO_MOVIMENTO: translate(dado.operacao.movement_object.status),
                    CURRICULO: dado.dsCurso,
                    ID_OPERACAO: dado.operacao.id,
                    ID_COBRANCA: dado.cobrancaId,
                    ID_SAQUE: dado.idSaque,
                    NOME_UNIDADE: dado.nomeUnidade,
                });
            });
        }

        const arrayNew = [...arrayDados, { ...totals }];
        return arrayNew;
    }, [data]);
};
