import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { IUnidadeCronogramaAulaDTO } from '../../models/happy-code-api.model';
import { Tags } from '../Tags';

interface Props {
    cronogramaAula: IUnidadeCronogramaAulaDTO[];
}

const CronogramaDiario: React.FC<Props> = ({ cronogramaAula }: Props) => {
    const today = new Date();

    const dayHours = Array(25)
        .fill(0)
        .map((_, i) => {
            const hour = i < 10 ? '0' + i : i;
            return hour + ':00';
        })
        .filter((hour) => {
            const [hourStr] = hour.split(':');
            const hourValue = parseInt(hourStr);

            return hourValue >= today.getHours();
        });

    const renderTurmaCard = (hour: string) => {
        const aulas = cronogramaAula && cronogramaAula.filter(
            (aula) => {
                const aulaHour = aula.horario.toString().substring(0, 2);
                const cardHour = hour.substring(0, 2);
                return aulaHour === cardHour;
            }
        );

        if (aulas?.length > 0) {
            return aulas.map((aula, index) => (
                <div key={index} className="aulas">
                    <div className="aula">
                        <div className="inset-red"></div>
                        <span style={{ fontSize: '0.5em' }}>
                            {aula.horario.toString().substring(0, 5)}
                        </span>
                        <h3>{aula.diaSemana}</h3>
                        <Tags tipo={aula.descricaoCurso} />
                    </div>
                </div>
            ));
        }
    };

    return (
        <div className="cal-body" style={{ borderRadius: 8, maxHeight: 745 }}>
            <div className="title">
                <h3>Cronograma de Hoje</h3>
                <Link to={`/franquia/turmas`}>
                    <Button
                        style={{
                            backgroundColor: '#f1df43',
                            borderRadius: 10,
                            color: '#0c306c',
                            fontSize: '0.8rem',
                        }}
                    >
                        Visão Semanal
                    </Button>
                </Link>
            </div>
            <div className="cards" style={{ height: 650, overflowX: 'hidden', padding: 10 }}>
                {dayHours.map(hour => (
                    <div className="card" key={hour}>
                        <h6>{hour}</h6>
                        <div style={{ width: '-webkit-fill-available' }}>
                            {cronogramaAula?.length > 0 && renderTurmaCard(hour)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CronogramaDiario;
